import React from 'react';



const Loader = () => {
  return (
    <div className="rings-loader">
      <div className="ring1"></div>
      <div className="ring2"></div>
      <div className="ring3"></div>
    </div>
  );
};


export default Loader;
