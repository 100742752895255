import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Alert, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEye } from 'react-icons/fa'; 
import DataTable from 'react-data-table-component';
import Loader from './Loader'; 
import { UserContext } from '../context/UserContext'; 
import useApi from '../api'; 
import styled from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
const ApplicationListPage = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [applicationStatus, setApplicationStatus] = useState('all'); 
  const { user } = useContext(UserContext); 
  const { getApplicationList, getApplicationByID, getStatusColor } = useApi();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const status = location.state?.applicationsStatus || 'all'; // Use status from location state
    setApplicationStatus(status);

    const fetchApplications = async () => {
      if (!user.token || !user.id) {
        setError('No authentication token or applicant ID found');
        setLoading(false);
        return;
      }

      try {
        const response = await getApplicationList(status);
        console.log(response);
        if (response.code === 200) {
          setApplications(response.data);
          setFilteredApplications(response.data); // Initialize filtered applications
        } else {
          setError(response.message || 'No applications found');
        }
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []); // Include status and hook in dependencies

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);
    const filtered = applications.filter((app) =>
      Object.values(app).some((value) =>
        value.toString().toLowerCase().includes(query)
      )
    );
    setFilteredApplications(filtered);
  };

  const handleViewClick = async (applicationId) => {
    try {
      const response = await getApplicationByID(applicationId); // Use getApplicationByID
     
      if (response.code === 200) {
        navigate(`/application/emi/${applicationId}`, { state: { emiDetails: response.data, profileData: response.data.applicantData } });
      } else {
        setError(response.message || 'Failed to fetch EMI details');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while fetching EMI details');
    }
  };

  const columns = [
    {
      name: 'S. No',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Loan Amount',
      selector: row => row.loan_amount,
      sortable: true,
    },
    {
      name: 'Loan Tenure',
      selector: row => row.loan_tenure,
      sortable: true,
    },
    {
      name: 'Applied Date',
      selector: row => row.applied_date,
      sortable: true,
    },
    {
      name: 'Disbursed Amount',
      selector: row => row.disbursed_amount,
      sortable: true,
    },
    {
      name: 'Disbursed Date',
      selector: row => row.disbursed_date,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.application_status,
      sortable: true,
      cell: row => (
        <div
          style={{
            backgroundColor: getStatusColor(row.application_status),
            color: '#fff',
            padding: '5px',
            borderRadius: '4px'
          }}
        >
          {row.application_status}
        </div>
      )
    },
    {
      name: 'Action',
      cell: row => (
        <Button
          variant="link"
          onClick={() => handleViewClick(row.id)}
        >
          <FaEye /> {/* View icon */}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Rows per page',
    rangeSeparatorText: 'of',
    noRowsPerPage: true
  };

  const customHeader = () => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <Form.Control
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        className="w-25"
      />
    </div>
  );

  return (
    <main id="main" className="main">
      <Container>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
               
                {loading ? (
                  <div className="text-center my-4">
                    <Loader />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredApplications}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    responsive
                    customStyles={{
                      rows: {
                        style: {
                          fontSize: '16px',
                        },
                      },
                      headCells: {
                        style: {
                          fontSize: '16px',
                        },
                      },
                    }}
                    title={customHeader()}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ApplicationListPage;
