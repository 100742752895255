import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Loader from './Loader';
import useApi from '../api'; // Adjust the import path as needed

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  const { getProfileByID } = useApi(); // Destructure the function from the hook

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfileByID();
         if (response.code === 200) {
          
          setProfile(response.data);
        } else {
          setError('Failed to fetch profile data.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching profile data.');
      }
    };

    fetchProfile();
  }, [getProfileByID]); 

  const ProfileTable = ({ profile, title }) => {
    if (!profile) return null;

    const rows = Object.entries(profile).map(([key, value]) => ({
      label: formatLabel(key),
      value: value || 'N/A',
    }));

    return (
      <Card className="glass-card">
        <Card.Body>
          <h5>{title}</h5>
          <Table striped bordered hover responsive className="profile-table">
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="font-weight-bold">{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  const formatLabel = (key) => {
    const labels = {
      name: 'Name',
      mobile: 'Mobile',
      dob: 'Date of Birth',
      gender: 'Gender',
      marital: 'Marital Status',
      care_name: 'Care Name',
      a_mobile: 'Alternate Mobile',
      email: 'Email',
      aadhaar: 'Aadhaar',
      pan: 'PAN',
      voter: 'Voter ID',
      address: 'Address',
      district: 'District',
      state: 'State',
      pincode: 'Pincode',
    };
    return labels[key] || key;
  };

  return (
    <main id="main" className="main">
      <Container>
        <Row>
          <Col>
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="mt-4 text-center">
                Welcome, {profile?.name || 'User'}{' '}
                <span className="waving-hand" role="img" aria-label="waving hand">👋</span>
              </h2>
            </motion.div>
            {error && <Alert variant="danger">{error}</Alert>}
            {profile ? (
              <Row className="mt-4">
                <Col md={6} className="mb-3">
                  <ProfileTable
                    profile={{
                      name: profile.name,
                      mobile: profile.mobile,
                      dob: profile.dob,
                      gender: profile.gender,
                      marital: profile.marital,
                      care_name: profile.care_name,
                      a_mobile: profile.a_mobile,
                      email: profile.email,
                    }}
                    title="Personal Information"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <ProfileTable
                    profile={{
                      aadhaar: profile.aadhaar,
                      pan: profile.pan,
                      voter: profile.voter,
                      address: profile.address,
                      district: profile.district,
                      state: profile.state,
                      pincode: profile.pincode,
                    }}
                    title="Address & Documents"
                  />
                </Col>
              </Row>
            ) : (
              <div className="text-center my-4">
                <Loader />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ProfilePage;
