// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter as Router } from 'react-router-dom';
// import App from './App';
// import { UserProvider } from './context/UserContext';

// // Create a root for the app
// const root = ReactDOM.createRoot(document.getElementById('root'));

// // Render the app
// root.render(
//   <Router>
//     <UserProvider>
//       <App />
//     </UserProvider>
//   </Router>
// );
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { UserProvider } from './context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </React.StrictMode>
);
