import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import useApi from '../api'; // Adjust the path as needed
import { Card, Row, Col, Container, Spinner, Alert, Form } from 'react-bootstrap';
const ActivityDataTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  const { getActivityData } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getActivityData();
        console.log('Fetched data:', result);

        if (result && result.code === 200 && Array.isArray(result.data)) {
          setData(result.data);
          setFilteredData(result.data); // Initialize filtered data
        } else {
          console.error('Unexpected data structure:', result);
          setData([]);
          setFilteredData([]);
        }
      } catch (error) {
        console.error('Error fetching activity data:', error);
        setData([]);
        setFilteredData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const filtered = data.filter(activity =>
      activity.message.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchText, data]); // Re-filter when searchText or data changes

  const columns = [
    { name: 'S. No.', selector: (row, index) => index + 1, sortable: true, width: '100px' },
    { name: 'Type', selector: row => row.type || 'N/A', sortable: true },
    { name: 'Description', selector: row => row.message || 'N/A', sortable: true },
    { name: 'Device', selector: row => row.device || 'N/A', sortable: true },
    { name: 'Created At', selector: row => row.created_at || 'N/A', sortable: true },
  ];

  return (
    <main id="main" className="main">
        <Card className="mb-4">
        <Card.Body>
      <div className="data-table-container">
      <Row>
      <Col xs={6}>

      <h5>User Activity</h5>
                 </Col>
      <Col xs={6}>

<Form.Control
                    type="text"
                    placeholder="Search EMI details..."
                    onChange={e => setSearchText(e.target.value)}
                    className="float-end mb-3 w-50"

                  />
                 </Col>
                     </Row>
                 
        <DataTable
        
          columns={columns}
          data={filteredData}
          progressPending={loading}
          customStyles={{
            rows: { style: { fontSize: '14px' } },
            headCells: { style: { fontSize: '14px' } },
            cells: { style: { padding: '8px' } },
          }}
          pagination
          highlightOnHover
          striped
          dense
          noDataComponent="No activity data available"
        />
      </div>
      </Card.Body>
          </Card>
    </main>
  );
};

export default ActivityDataTable;
