import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { PiUserCirclePlusFill } from "react-icons/pi";
import { HiOutlineChartBar } from "react-icons/hi2";
import { GiChart } from "react-icons/gi";
import { MdOutlinePersonSearch } from "react-icons/md";
import { UserContext } from '../context/UserContext';
import useApi from '../api'; // Import useApi instead of logout

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const [username, setUsername] = React.useState('');
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { logout } = useApi(); // Destructure logout from useApi

  React.useEffect(() => {
    const userData = sessionStorage.getItem('user');
    if (userData) {
      const parsedData = JSON.parse(userData);
      if (parsedData && parsedData.name) {
        setUsername(parsedData.name);
      }
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logout(); // Call logout without any arguments
      // Clear session storage
      sessionStorage.clear();
      // Redirect to login page
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <header className={`header fixed-top d-flex flex-column flex-md-row align-items-center justify-content-between p-3 bg-light ${!isSidebarOpen ? 'full-width' : ''}`}>
      <div className="d-flex w-100 align-items-center justify-content-between">
        <button
          className="toggle-sidebar-btn btn-outline-primary d-md-inline"
          type="button"
          onClick={toggleSidebar}
        >
          <svg className="svgburg" width="25" height="25" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="path1" d="M1.5 7L27.5 33C27.5 33 30.5 36 36.5 40.5C42.5 45 48 33.5 41.5 33C35 32.5 2 33 2 33" stroke="black" strokeWidth="2" />
            <path className="path2" d="M2 33L28 7C28 7 33.5 1 37 1C40.5 1 43 6.20692 40 7C37 7.79308 1 7 1 7" stroke="black" strokeWidth="2" />
            <path className="mline" d="M1.5 20H28.5" stroke="black" strokeWidth="2" />
          </svg>
        </button>
        <div className="search-box">
          <input type="text" placeholder="search..." />
          <MdOutlinePersonSearch size={20} />
        </div>
        <nav className="d-flex align-items-center ml-auto">
          <div className="dropdown ml-3">
            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" role="button" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="d-none d-md-block ps-2 me-2">{user.name || 'User'}</span>
              <PiUserCirclePlusFill size={30} />
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
              <li><Link className="dropdown-item" to="/profile">
                <i className="bi bi-person"></i> My Profile
              </Link></li>
              <li><Link className="dropdown-item" to="/change-password">
                <i className="bi bi-gear"></i> Account Settings
              </Link></li>
              {/* <li><Link className="dropdown-item" to="/session-detail">
                <HiOutlineChartBar size={15}/> Session Detail
              </Link></li>
              <li><Link className="dropdown-item" to="/activitylist">
                <GiChart size={15}/> Activity List
              </Link></li> */}
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  <i className="bi bi-box-arrow-right"></i> Log Out
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
