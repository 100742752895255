import React, { useState } from 'react';
import { Container, Row, Col, Card, Alert, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useApi from '../api'; // Adjust the path to where useApi is located
import { useNavigate } from 'react-router-dom';

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { changePassword, logout } = useApi(); // Use the hook to get changePassword and logout functions
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await changePassword(currentPassword, newPassword); // Call the API function
      if (response.code === 200) {
        setSuccess('Password changed successfully');
        await logout(); // Call the logout function
        navigate('/login'); // Redirect to login page
      } else {
        setError('Error changing password');
      }
    } catch (error) {
      setError('Error changing password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <main id="main" className="main">
      <Container>
        <Row>
          <Col xs={12} md={6} className="mx-auto">
            <Card className="custom-card">
              <Card.Body>
                <h2 className="text-center my-4">Change Password</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                {loading ? (
                  <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4 position-relative" controlId="currentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type={showCurrentPassword ? 'text' : 'password'}
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showCurrentPassword ? faEyeSlash : faEye}
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        className="position-absolute top-50 mt-2 end-0 me-3 cursor-pointer"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative" controlId="newPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showNewPassword ? faEyeSlash : faEye}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="position-absolute top-50 mt-2 end-0 me-3 cursor-pointer"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative" controlId="confirmPassword">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="position-absolute top-50 mt-2 end-0 me-3 cursor-pointer"
                      />
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button className="custom-button text-white w-50" type="submit" style={{ backgroundColor: '#006666', borderColor: '#006666' }}>
                        Change Password
                      </Button>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ChangePasswordPage;
