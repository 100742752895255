import axios from 'axios';
import { useContext, useMemo } from 'react';
import { UserContext } from './context/UserContext';
import { useNavigate } from 'react-router-dom';

const BASE_URL = 'https://vcredil.com/apis/root/';

const useApi = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  if (!user || !user.token) {
    throw new Error('User is not authenticated.');
  }

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.token || '',
      },
    });

    // Add a response interceptor
    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 498) {
          // Clear session storage
          sessionStorage.removeItem('user');
          setUser(null); // Update the context to reflect logout
          // Redirect to login page
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [user.token, navigate, setUser]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'paid':
        return '#008000'; // Green
      case 'pending':
        return '#FF0000'; // Red
      case 'overdue':
        return '#FFA500'; // Orange
      case 'approved':
        return '#008000'; // Green
      case 'rejected':
        return '#FF0000'; // Red
      case 'closed':
        return '#FFA500'; // Orange
      default:
        return '#000000'; // Default to Black if no status matches
    }
  };

  const getProfileByID = async () => {
    try {
      const response = await api.post('common.php', {
        action: 'profileByID',
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile:', error);
      throw error;
    }
  };

  const getApplicationList = async (application_status) => {
    try {
      const response = await api.post('applicant.php', {
        action: 'applicationList',
        application_status,
        applicant_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching application list:', error);
      throw error;
    }
  };

  const getActivityData = async () => {
    try {
      const response = await api.post('common.php', {
        action: 'activity',
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching activity data:', error);
      throw error;
    }
  };

  const getSessionList = async () => {
    try {
      const response = await api.post('common.php', {
        action: 'session',
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteSession = async (session_id) => {
    try {
      const response = await api.post('common.php', {
        action: 'deleteSession',
        session_id,
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting session:', error);
      throw error;
    }
  };

  const getDocumentList = async () => {
    try {
      const response = await api.post('applicant.php', {
        action: 'documentList',
        applicant_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching document list:', error);
      throw error;
    }
  };

  const getDocumentType = async () => {
    try {
      const response = await api.post('common.php', {
        action: 'documentType',
        user_id: user.id,
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching document types:', error);
      throw error;
    }
  };

  const addDocument = async (documentData) => {
    try {
      const response = await api.post('applicant.php', {
        action: 'addDocument',
        ...documentData,
        applicant_id: user.id,
        added_by: user.id,
      });
      return response.data.message;
    } catch (error) {
      console.error('Error adding document:', error);
      throw error;
    }
  };

  const changePassword = async (old_password, new_password) => {
    try {
      const response = await api.post('common.php', {
        action: 'changePassword',
        old_password,
        new_password,
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error changing password:', error);
      throw error;
    }
  };

  const count = async () => {
    try {
      const response = await api.post('applicant.php', {
        action: 'count',
        user_id: user.id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching count:', error);
      throw error;
    }
  };

  const getApplicationByID = async (application_id) => {
    try {
      const response = await api.post('common.php', {
        action: 'applicationByID',
        application_id,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching application by ID:', error);
      throw error;
    }
  };

  const getProducts = async () => {
    try {
      const response = await api.post('common.php', {
        action: 'product',
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const getPurposes = async (product_id) => {
    try {
      const response = await api.post('common.php', {
        action: 'purpose',
        product_id,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const addApplicant = async ({
    product_id,
    purpose_id,
    loan_amount,
    loan_tenure,
    district,
    state,
  }) => {
    try {
      const response = await api.post('common.php', {
        action: 'addApplication',
        product_id,
        purpose_id,
        loan_amount,
        loan_tenure,
        district,
        state,
        applicant_id: user.id,
        added_by: 1,
      });
      return response.data;
    } catch (error) {
      console.error('Error adding applicant:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await api.post('common.php', {
        action: 'logout',
        user_id: user.id,
        token: user.token,
      });
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  return {
    getStatusColor,
    getProfileByID,
    getApplicationList,
    getActivityData,
    getSessionList,
    deleteSession,
    getDocumentList,
    getDocumentType,
    addDocument,
    count,
    addApplicant,
    getApplicationByID,
    getProducts,
    getPurposes,
    changePassword,
    logout,
  };
};

export const loginPassword = async (mobile, password) => {
  try {
    const response = await axios.post(`${BASE_URL}common.php`, {
      action: 'passwordLogin',
      mobile,
      password,
    }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const otpLogin = async (mobile, otp) => {
  try {
    const response = await axios.post(`${BASE_URL}common.php`, {
      action: 'otpLogin',
      mobile,
      otp,
    }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('OTP verification error:', error);
    throw error;
  }
};

export default useApi;
