import React, { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch user data from session storage
  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing user data from session storage:', error);
      }
    }
    setLoading(false);
  }, []);

  // Fetch session data if `s_id` is present in the URL query parameters
  useEffect(() => {
    const fetchSessionData = async (s_id) => {
      if (!s_id) return;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", user?.token || "");

      const raw = JSON.stringify({
        "action": "getSession",
        "s_id": s_id,
      });

      try {
        const response = await fetch('https://vcredil.com/apis/root/common.php', {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        });
        const data = await response.json();
        if (data.code === 200) {
          setUser(data.data);
          sessionStorage.setItem('user', JSON.stringify(data.data));
          navigate('/dashboard'); // Redirect after session data is successfully fetched
        } else {
          console.error('Error fetching session data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    const queryParams = new URLSearchParams(location.search);
    const s_id = queryParams.get('s_id');

    if (s_id) {
      fetchSessionData(s_id);
    } else {
      setLoading(false); // Ensure loading is set to false if no session data is fetched
    }
  }, [location.search, user?.token, navigate]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
