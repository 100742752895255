import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FaEye, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { Card, Row, Col, Container, Spinner, Alert, Form } from 'react-bootstrap';
import useApi from '../api';
import { UserContext } from '../context/UserContext';
import Loader from './Loader';

Modal.setAppElement('#root');

const SessionList = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { getSessionList, deleteSession } = useApi();
  const { user } = React.useContext(UserContext);
  
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const result = await getSessionList();
        if (result && result.code === 200 && Array.isArray(result.data)) {
          setSessions(result.data);
          setFilteredData(result.data); // Initialize filtered data
        } else {
          console.error('Unexpected data structure:', result);
          setSessions([]);
          setFilteredData([]);
        }
      } catch (err) {
        console.error('Error fetching session data:', err);
        setSessions([]);
        setFilteredData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [getSessionList]);
  const handleView = (id) => {
    // Handle view action
  };

  const handleDelete = async () => {
    if (sessionToDelete) {
      try {
        await deleteSession(sessionToDelete);
        toast.success('Deleted session successfully');
        setSessions((prevSessions) => prevSessions.filter((session) => session.id !== sessionToDelete));
      
      } catch (error) {
        toast.error(`Failed to delete session ${sessionToDelete}: ${error.message}`);
      } finally {
        setIsModalOpen(false);
        setSessionToDelete(null);
      }
    }
  };
  const openModal = (id) => {
    setSessionToDelete(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSessionToDelete(null);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredData(
      sessions.filter((session) =>
        session.device.toLowerCase().includes(value) || 
        session.session.toLowerCase().includes(value)
      )
    );
  };

  const columns = [
    {
      name: 'S. No.',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Token',
      selector: (row) => row.session,
      sortable: true,
      width: '400px',
    },
    {
      name: 'Device',
      selector: (row) => (
        <div className="device-cell">
          <span>{row.device}</span>
          {row.token === user.token && (
            <span className="current-session">(current session)</span>
          )}
        </div>
      ),
      sortable: true,
      width: '300px',
    },
    {
      name: 'Created At',
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="action-buttons">
          <button onClick={() => handleView(row.id)} className="action-button view-button">
            <FaEye />
          </button>
          <button
            onClick={() => openModal(row.id)}
            className="action-button delete-button"
            disabled={row.token === user.token}
          >
            <FaTrash />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  if (loading) return <p><Loader /></p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <main id="main" className="main">
          
           <Card className="mb-4">
        <Card.Body>
      <div className="data-table-container">
      <Row>
      <Col xs={6}>

      <h5>Session List</h5>
                 </Col>
      <Col xs={6}>

<Form.Control
                    type="text"
                    placeholder="Search EMI details..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="float-end mb-3 w-50"

                  />
                 </Col>
                     </Row>
        <DataTable
          columns={columns}
          data={filteredData}
          progressPending={loading}
          pagination
          highlightOnHover
          responsive
          customStyles={{
            rows: {
              style: {
                fontSize: '14px',
              },
            },
            headCells: {
              style: {
                fontSize: '14px',
              },
            },
            cells: {
              style: {
                padding: '8px',
              },
            },
          }}
          striped
          dense
          noDataComponent="No Session data available"
        />
      </div>
      </Card.Body>
      </Card>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Deletion"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="modal-title">Confirm Deletion</h2>
        <p>Are you sure you want to delete this session?</p>
        <div className="modal-actions">
          <button onClick={handleDelete} className="modal-button confirm-button">Yes, Delete</button>
          <button onClick={closeModal} className="modal-button cancel-button">Cancel</button>
        </div>
      </Modal>

      <ToastContainer />
    </main>
  );
};

export default SessionList;
