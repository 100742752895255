import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import { GrDocumentUpdate, GrDocumentText } from "react-icons/gr";
import { ImProfile } from "react-icons/im";
import { BiLogOutCircle } from "react-icons/bi";
import { HiPencilSquare } from "react-icons/hi2";
import { PiChartLineUpBold } from "react-icons/pi";
import { GrUserSettings } from "react-icons/gr";
import { PiChartBarFill } from "react-icons/pi";
import { UserContext } from '../context/UserContext';
import logo from '../images/VwhiteLogo.png';
import logoclosed from '../images/logo.png';
import useApi from '../api';

const Sidebar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { logout } = useApi();

  const handleLogout = async () => {
    try {
      await logout();
      sessionStorage.clear();
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <aside className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul className="sidebar-nav">
        <a href="/dashboard" className="logo d-flex align-items-center">
          <img src={isSidebarOpen ? logo : logoclosed} alt="Logo" className="mr-2" />
        </a>
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link">
          <i className="fa fa-thumb-tack"></i>
            <i className="bi bi-grid"></i>
            {isSidebarOpen && <span>Dashboard</span>}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/loanapply" className="nav-link">
            <HiPencilSquare size={20} />
            <i className="fa fa-thumb-tack"></i>
            {isSidebarOpen && <span className='ms-3'>New Loan Apply</span>}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/applicationlist" className="nav-link">
            <GrDocumentText size={20} />
            <i className="fa fa-thumb-tack"></i>
            {isSidebarOpen && <span className='ms-3'>Applications</span>}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/documentlist" className="nav-link">
            <GrDocumentUpdate size={20} />
            <i className="fa fa-thumb-tack"></i>
            {isSidebarOpen && <span className='ms-3'>Document List</span>}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/profile" className="nav-link">
            <ImProfile size={20} />
            <i className="fa fa-thumb-tack"></i>
            {isSidebarOpen && <span className='ms-3'>Profile</span>}
          </NavLink>
        </li>
          <li className="nav-item">
          <Dropdown as="li" className="nav-item">
            <Dropdown.Toggle as="div" className="nav-link">
              <GrUserSettings size={20}/>
              <i className="fa fa-thumb-tack"></i>
              {isSidebarOpen && <span className='ms-3'>Settings</span>}
            </Dropdown.Toggle>
            
            <Dropdown.Menu className="sidebar-dropdown-menu">
              <Dropdown.Item as={NavLink} to="/session-detail"> <PiChartLineUpBold size={20}/> <span className='ms-3'>Session Detail</span></Dropdown.Item>
              <Dropdown.Item as={NavLink} to="/activitylist"> <PiChartBarFill size={20}/><span className='ms-3'>Activity List</span></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        
        <li className="nav-item mt-auto">
          <button className="nav-link btn btn-link" onClick={handleLogout}>
            <BiLogOutCircle size={20} />
            <i className="fa fa-thumb-tack"></i>
            {isSidebarOpen && <span className='ms-3'>Logout</span>}
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
