import React, { useEffect, useState } from 'react';
import useApi from '../api'; // Adjust the import path based on your project structure
import DataTable from 'react-data-table-component';
import { Spinner, Alert, Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Loader from './Loader';
const DocumentList = () => {
  const { getDocumentList, getDocumentType, addDocument } = useApi();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [formData, setFormData] = useState({});
  const [uploadStatus, setUploadStatus] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await getDocumentList();
   
        if (response && response.code === 200 && Array.isArray(response.data)) {
          setDocuments(response.data);
        } else {
          setError('Unexpected data format received');
        }
      } catch (err) {
        console.error('Failed to fetch documents:', err);
        setError('Failed to fetch documents');
      } finally {
        setLoading(false);
      }
    };
  
    fetchDocuments();
  }, []);

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const types = await getDocumentType();
        setDocumentTypes(types);
      } catch (err) {
        console.error('Failed to fetch document types:', err);
      }
    };

    fetchDocumentTypes();
  }, [getDocumentType]);

  const getHintMessage = (docKey) => {
    switch (docKey) {
      case 'aadhaar':
        return 'Aadhaar number must be 12 digits long.';
      case 'pan':
        return 'PAN should be 10 characters long (e.g., ABCDE1234F).';
      case 'passport':
        return 'Passport number should be 8 characters long.';
      default:
        return `Please enter a valid ${docKey} number.`;
    }
  };

  const getErrorMessage = (docKey) => {
    switch (docKey) {
      case 'aadhaar':
        return 'Aadhaar number must be exactly 12 digits.';
      case 'pan':
        return 'PAN number must be exactly 10 characters.';
      case 'passport':
        return 'Passport number must be exactly 8 characters.';
      default:
        return `Invalid ${docKey} number.`;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          document_file: reader.result,
        }));
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setError('Error reading file');
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
  
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      document_number: '',
    }));
  
    const isValid = validateInput(value);
    if (isValid) {
      setShowHint(false);
    } else {
      setShowHint(true);
    }
  
    setFormData((prev) => ({
      ...prev,
      document_number: value,
    }));
  };

  const validateInput = (value) => {
    if (!selectedType) return true;

    const docKey = selectedType.name.toLowerCase().replace(/\s+/g, '');
    switch (docKey) {
      case 'aadhaar':
        return /^\d{12}$/.test(value);
      case 'pan':
        return /^[A-Z]{5}\d{4}[A-Z]$/.test(value);
      case 'passport':
        return /^[A-Z]\d{7}$/.test(value);
      case 'voterid':
        return /^[A-Z]{3}\d{7}$/.test(value); 
      case 'drivinglicence':
        return /^[A-Z]{2}\d{2} \d{11}$/.test(value);
      default:
        return true;
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.document_number) {
      errors.document_number = `${selectedType?.name.toUpperCase()} number is required.`;
    } else if (!validateInput(formData.document_number)) {
      errors.document_number = getErrorMessage(selectedType?.name.toLowerCase().replace(/\s+/g, ''));
    }
    if (!formData.document_file) {
      errors.document_file = `${selectedType?.name.toUpperCase()} file is required.`;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowHint(false);
    if (!validateForm()) {
      return;
    }
    try {
      const documentDataToSend = {
        document_type: selectedType?.name.toLowerCase().replace(/\s+/g, ''),
        document_number: formData.document_number,
        document_file: formData.document_file,
      };
     
      const response = await addDocument(documentDataToSend);
      setUploadStatus('uploaded');
      toast.success('Document uploaded successfully.');
      setModalShow(false);
    } catch (error) {
      console.error('Error uploading document:', error);
      setUploadStatus('failed');
      toast.error(`Error uploading document: ${error.message}`);
    }
  };

  const handleAddDocument = () => {
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedType(null);
    setFormData({});
    setFormErrors({});
    setShowHint(false);
  };

  const handleTypeChange = (e) => {
    const typeId = e.target.value;
    const type = documentTypes.find(docType => docType.id === parseInt(typeId, 10));
    setSelectedType(type);
    setFormData((prev) => ({
      ...prev,
      document_number: '',
    }));
    setFormErrors((prev) => ({
      ...prev,
      document_number: '',
    }));
  };

  const BASE_FILE_URL = 'https://vcredil.com/applicant-documents/';
  const columns = [
    { name: 'Document Type', selector: row => row.doc_type, sortable: true },
    { name: 'Document Number', selector: row => row.doc_number, sortable: true },
    {
      name: 'Document File',
      selector: row => (
        <a href={`${BASE_FILE_URL}${row.doc_file}`} target="_blank" rel="noopener noreferrer" style={{ color: '#006666' }}>
          View Document
        </a>
      ),
    },
    { name: 'Upload Date', selector: row => row.created_at, sortable: true },
  ];

  return (
    <main id="main" className="main">
      <ToastContainer />
      <div className="document-list shadow">
        {loading ? (
          <div className="text-center">
            <Loader/>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            <DataTable
              title={
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h4>Uploaded Documents</h4>
                  <button className="btn btn-primary" style={{ backgroundColor: '#006666', borderColor: '#006666' }} onClick={handleAddDocument}>
                    + Add Document
                  </button>
                </div>
              }
              columns={columns}
              data={documents}
              pagination
            />
            <Modal show={modalShow} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="documentType">
                    <Form.Label>Select Document Type</Form.Label>
                    <Form.Control as="select" onChange={handleTypeChange} value={selectedType?.id || ''}>
                      <option value="">Select...</option>
                      {documentTypes.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="documentNumber">
                    <Form.Label>Document Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.document_number || ''}
                      onChange={handleNumberChange}
                      isInvalid={!!formErrors.document_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.document_number}
                    </Form.Control.Feedback>
                    {showHint && (
                      <Form.Text className="text-muted">
                        {getHintMessage(selectedType?.name.toLowerCase().replace(/\s+/g, ''))}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="documentFile">
                    <Form.Label>Document File</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      isInvalid={!!formErrors.document_file}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.document_file}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Upload
                  </Button>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </main>
  );
};

export default DocumentList;
