import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../api'; // Adjust the import path as needed
import Loader from './Loader';
const allowedTenureValues = [3, 6, 9, 12, 15, 18, 24, 28, 32, 36]; // Define allowed values

const LoanForm = () => {
  const [loanType, setLoanType] = useState('');
  const [loanPurpose, setLoanPurpose] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [errors, setErrors] = useState({});
  const [state, setState] = useState(''); // State for state
  const [district, setDistrict] = useState(''); // State for district
  const [products, setProducts] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [profile, setProfile] = useState(null);

  const api = useApi();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await api.getProfileByID();
  
        if (profileData.code === 200) {  
          setProfile(profileData.data);
          setState(profileData.data.state || ''); // Ensure fallback to empty string
          setDistrict(profileData.data.district || ''); // Ensure fallback to empty string
        } else {
          setErrors(prevErrors => ({ ...prevErrors, global: profileData.message }));
        }
      } catch (err) {
        setErrors(prevErrors => ({ ...prevErrors, global: err.message || 'An error occurred' }));
      }
    };
  
    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await api.getProducts();
        setProducts(productsData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchPurposesOnProductChange = async () => {
      if (loanType) {
        try {
          const purposesData = await api.getPurposes(loanType);
          setPurposes(purposesData);
        } catch (error) {
          console.error('Error fetching purposes:', error);
        }
      }
    };

    fetchPurposesOnProductChange();
  }, [loanType]);

  const filteredPurposes = purposes.filter(purpose => purpose.product_id === parseInt(loanType));

  const validateAmount = (amount) => {
    const selectedProduct = products.find(product => product.id === parseInt(loanType));
    if (!amount || amount <= 0) {
      return "Please enter a valid loan amount greater than 0.";
    } else if (selectedProduct && (amount < selectedProduct.min_amount || amount > selectedProduct.max_amount)) {
      return `Amount must be between ${selectedProduct.min_amount} and ${selectedProduct.max_amount}.`;
    }
    return "";
  };

  const validateTenure = (tenure) => {
    const parsedTenure = parseInt(tenure);
    if (!tenure || parsedTenure <= 0) {
      return "Please enter a valid loan tenure greater than 0.";
    } else if (!allowedTenureValues.includes(parsedTenure)) {
      return `Enter a valid Loan Tenure. Allowed values are ${allowedTenureValues.join(', ')} months.`;
    }
    return "";
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLoanAmount(value);
    setErrors(prevErrors => ({ ...prevErrors, loanAmount: validateAmount(value) }));
  };

  const handleTenureChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLoanTenure(value);
    setErrors(prevErrors => ({ ...prevErrors, loanTenure: validateTenure(value) }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const isValidAmount = !validateAmount(loanAmount);
    const isValidTenure = !validateTenure(loanTenure);  
    if (isValidAmount && isValidTenure) {
      try {
        const requestData = {
          product_id: loanType,
          purpose_id: loanPurpose,
          loan_amount: loanAmount,
          loan_tenure: loanTenure,
          state: state,
          district: district,
          // applicant_id: user.id, // Not needed here; it's handled in addApplicant
        };
  
        const response = await api.addApplicant(requestData);   
        if (response.code === 200) {
          toast.success('Application submitted successfully!');
          setLoanType('');
          setLoanPurpose('');
          setLoanAmount('');
          setLoanTenure('');
          setErrors({});
        } else {
          toast.error(`Failed to submit the application. Error: ${response.message}`);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error(`Failed to submit the application. Error: ${error.response?.data?.message || error.message}`);
      }
    } else {
      toast.error('Please correct the errors in the form.');
    }
  };
  
  

  return (
    <main id="main" className="main">
      <div className="container mt-5">
        <div className="card">
          <div className="card-header">
            <h4 className="mb-0">Loan Application Form</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              {errors.global && <div className="alert alert-danger">{errors.global}</div>}
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="loanType" className="form-label">Loan Type *</label>
                  <select
                    id="loanType"
                    className={`form-select ${errors.loanType ? 'is-invalid' : ''}`}
                    value={loanType}
                    onChange={(e) => setLoanType(e.target.value)}
                    required
                  >
                    <option value="">Select Loan Type</option>
                    {products.length > 0 ? (
                      products.map(product => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No products available</option>
                    )}
                  </select>
                </div>

                <div className="col-md-6">
                  <label htmlFor="loanPurpose" className="form-label">Loan Purpose *</label>
                  <select
                    id="loanPurpose"
                    className={`form-select ${errors.loanPurpose ? 'is-invalid' : ''}`}
                    value={loanPurpose}
                    onChange={(e) => setLoanPurpose(e.target.value)}
                    required
                    disabled={!loanType}
                  >
                    <option value="">Select Loan Purpose</option>
                    {filteredPurposes.length > 0 ? (
                      filteredPurposes.map(purpose => (
                        <option key={purpose.id} value={purpose.id}>
                          {purpose.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No purposes available</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="loanAmount" className="form-label">Loan Amount (INR) *</label>
                  <input
                    type="number"
                    id="loanAmount"
                    className={`form-control ${errors.loanAmount ? 'is-invalid' : ''}`}
                    value={loanAmount}
                    onChange={handleAmountChange}
                    required
                    placeholder="Enter Amount"
                  />
                  {errors.loanAmount && <small className="text-danger">{errors.loanAmount}</small>}
                </div>

                <div className="col-md-6">
                  <label htmlFor="loanTenure" className="form-label">Loan Tenure (Months) *</label>
                  <input
                    type="number"
                    id="loanTenure"
                    className={`form-control ${errors.loanTenure ? 'is-invalid' : ''}`}
                    value={loanTenure}
                    onChange={handleTenureChange}
                    required
                    placeholder="Enter Tenure"
                  />
                  {errors.loanTenure && <small className="text-danger">{errors.loanTenure}</small>}
                </div>
              </div>

             
              <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#006666', borderColor: '#006666' }}>Submit</button>
         
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
};

export default LoanForm;
