import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/index.css';
import ChangePasswordPage from './pages/ChangePasswordPage';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProfilePage from './pages/ProfilePage';
import ApplicationData from './pages/ApplicationData';
import NewLoan from './pages/NewLoan';
import Sessiondetail from './pages/Sessiondetail';
// import DocumentUploadForm from './pages/DocumentUploadForm.jsx_cop3';
import Documentlist from './pages/DocumentList';
import Loader from './pages/Loader';
import EMIList from './pages/EMIList';
import Layout from './pages/Layout';
import ActivityList from './pages/ActivityList';
import { UserContext } from './context/UserContext';

const App = () => {
  const { user, loading } = React.useContext(UserContext);

  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="/login" element={ <LoginPage />} />
      <Route element={user ? <Layout /> : <Navigate to="/login" />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/applicationlist" element={<ApplicationData />} />
        <Route path="/application/emi/:id" element={<EMIList />} />
        {/* <Route path="/documentupload" element={<DocumentUploadForm />} /> */}
        <Route path="/documentlist" element={<Documentlist />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/loanapply" element={<NewLoan />} />
        <Route path="/session-detail" element={<Sessiondetail />} />
        <Route path="/activitylist" element={<ActivityList />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

export default App;
