import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginPassword, otpLogin } from '../api';
import { TextField, Button, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginPage = () => {
  const { setUser } = useContext(UserContext);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpLogin, setIsOtpLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await loginPassword(mobile, password);
      if (response.code === 200) {
        const userData = response.data;
        setUser(userData);
        sessionStorage.setItem('user', JSON.stringify(userData));

        toast.success('Login successful! Redirecting...', {
          position: 'top-right',
          autoClose: 3000,
        });

        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        toast.error(response.message || 'Login failed. Please try again.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || 'Login failed. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRequestOrVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await otpLogin(mobile, otp);
      if (response.code === 200) {
        const userData = response.data;
        setUser(userData);
        sessionStorage.setItem('user', JSON.stringify(userData));
        toast.success('OTP verified successfully! Redirecting...', {
          position: 'top-right',
          autoClose: 3000,
        });

        setTimeout(() => {
          navigate('/dashboard');
        }, 5000);
      } else {
        toast.error(response.message || 'Failed to verify OTP', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('OTP error:', error);
      toast.error(error.response?.data?.message || 'OTP process failed. Please try again.', {
          position: 'top-right',
          autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#DDF7E3',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 400,
          width: '100%',
          backgroundColor: '#c7e8ca',
          borderRadius: 4,
          position: 'relative',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Login
        </Typography>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {!isOtpLogin ? (
          <>
            <form onSubmit={handleLogin}>
              <TextField
                label="Mobile Number"
                type="text"
                fullWidth
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                margin="normal"
                required
              />
              <Box sx={{ position: 'relative', marginTop: 2 }}>
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={() => setShowPassword(!showPassword)}
                  className="position-absolute"
                  style={{
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#4a7d47',
                  marginTop: 2,
                  '&:hover': {
                    fontSize: '20px',
                    backgroundColor: '#5D9C59',
                    transition: 'all 0.1s',
                    boxShadow: '0 0 20px -2px #2D5C29',
                  },
                }}
              >
                Login
              </Button>
            </form>
            <Button
              variant="text"
              fullWidth
              onClick={() => setIsOtpLogin(true)}
              sx={{ marginTop: 2, textTransform: 'none' }}
            >
              Login with OTP
            </Button>
          </>
        ) : (
          <>
            <form onSubmit={handleRequestOrVerifyOTP}>
              <TextField
                label="Mobile Number"
                type="text"
                fullWidth
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                margin="normal"
                required
              />
              <Box sx={{ position: 'relative', marginTop: 2 }}>
                <TextField
                  label="OTP"
                  type={showOtp ? 'text' : 'password'}
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  margin="normal"
                  required
                />
                <FontAwesomeIcon
                  icon={showOtp ? faEyeSlash : faEye}
                  onClick={() => setShowOtp(!showOtp)}
                  className="position-absolute"
                  style={{
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#4a7d47',
                  marginTop: 2,
                  '&:hover': {
                    fontSize: '20px',
                    backgroundColor: '#5D9C59',
                    transition: 'all 0.1s',
                    boxShadow: '0 0 20px -2px #2D5C29',
                  },
                }}
              >
                {otp ? 'Verify OTP' : 'Request OTP'}
              </Button>
            </form>
            <Button
              variant="text"
              fullWidth
              onClick={() => setIsOtpLogin(false)}
              sx={{ marginTop: 2, textTransform: 'none' }}
            >
              Back to Login with Password
            </Button>
          </>
        )}
      </Paper>
      <ToastContainer />
    </Box>
  );
};

export default LoginPage;
