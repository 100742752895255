import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { FaCircleDollarToSlot, FaFileCircleXmark } from "react-icons/fa6";
import { MdOutlinePendingActions } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";
import { IoLockClosed } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { count } from '../api'; // Import API functions
import { Pie, Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import Calendar from 'react-calendar';
import realImage from '../images/real.jpg';
import useApi from '../api';
import Loader from './Loader'; 
import 'react-calendar/dist/Calendar.css';

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [growthRate, setGrowthRate] = useState(0);
  const [rejectedRate, setRejectedRate] = useState(0);
  const [closedRate, setClosedRate] = useState(0);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [barChartData, setBarChartData] = useState({ labels: [], datasets: [] });
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const { count } = useApi();
  
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await count();
        
        if (response.code === 200) {
          const { total_application, pending_application, approved_application, disbursed_application, rejected_application, closed_application } = response.data;
          setTotalUsers(total_application);
          setTotalRevenue(pending_application);
          setTotalSales(approved_application);
          setGrowthRate(disbursed_application);
          setRejectedRate(rejected_application);
          setClosedRate(closed_application);
        }
      } catch (error) {
        console.error('Error fetching count data:', error);
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    const fetchChartData = async () => {
      // Simulate fetching data for the chart
      const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Sales Over Time',
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ],
      };

      // Check if data is valid
      if (data.labels && data.datasets) {
        setChartData(data);
      }
    };

    fetchChartData();
  }, []);

  useEffect(() => {
    const fetchBarChartData = async () => {
      // Simulate fetching bar chart data
      const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Monthly Sales',
            data: [30, 50, 70, 90, 60, 85, 75],
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
          },
        ],
      };

      // Check if data is valid
      if (data.labels && data.datasets) {
        setBarChartData(data);
      }
    };

    fetchBarChartData();
  }, []);

  useEffect(() => {
    const createBubbles = (containerId, bubbleColor) => {
      const container = document.getElementById(containerId);
      if (!container) return;

      // Clear previous bubbles
      container.innerHTML = '';

      for (let i = 0; i < 10; i++) {
        const bubble = document.createElement('div');
        bubble.className = 'bubble';
        bubble.style.backgroundColor = bubbleColor;
        bubble.style.left = `${Math.random() * 100}%`;

        // Set random size for the bubble
        const size = Math.random() * 15 + 10; // Between 10px and 25px
        bubble.style.width = `${size}px`;
        bubble.style.height = `${size}px`;

        // Set random animation duration for the bubble
        const duration = Math.random() * 3 + 2; // Between 2s and 5s
        bubble.style.animationDuration = `${duration}s`;

        container.appendChild(bubble);
      }
    };

    createBubbles('card1-bubbles', '#006666');
    createBubbles('card2-bubbles', 'rgba(255, 165, 0, 0.5)');
    createBubbles('card3-bubbles', 'rgb(61 197 10 / 50%)');
    createBubbles('card4-bubbles', '#8ec9d2');
    createBubbles('card5-bubbles', 'rgba(255, 0, 0, 0.5)');
    createBubbles('card6-bubbles', 'rgba(255, 255, 0, 0.5)');
  }, [totalUsers, totalRevenue, totalSales, growthRate, rejectedRate, closedRate]);

  const handleCardClick = async (status) => {
    try {
      navigate('/applicationlist', { state: { applicationsStatus: status } });
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
      <Row className="mb-4">
          <Col md={12}>
            <Row className="g-4">
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('all')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-primary me-3">
                      <FaUsers size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-primary bold-large">{totalUsers}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Total</Card.Title>
                    </div>
                    <div id="card1-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('Pending')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-warning me-3">
                      <FaCircleDollarToSlot size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-warning bold-large">{totalRevenue}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Pending</Card.Title>
                    </div>
                    <div id="card2-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('Approved')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-success me-3">
                      <FaFileCircleXmark size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-success bold-large">{totalSales}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Approved</Card.Title>
                    </div>
                    <div id="card3-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Row className="g-4">
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('Disbursed')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-info me-3">
                      <MdOutlinePendingActions size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-info bold-large">{growthRate}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Disbursed</Card.Title>
                    </div>
                    <div id="card4-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('Rejected')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-danger me-3">
                      <IoLockClosed size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-danger bold-large">{rejectedRate}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Rejected</Card.Title>
                    </div>
                    <div id="card5-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card 
                  className="shadow-lg border-0 dashboard-card rounded gradient-card" 
                  onClick={() => handleCardClick('Closed')}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body className="d-flex align-items-center p-3">
                    <div className="icon-circle text-secondary me-3">
                      <GrCompliance size={30} />
                    </div>
                    <div className="text-center flex-grow-1">
                      <Card.Text className="h3 mb-0 text-secondary bold-large">{closedRate}</Card.Text>
                      <Card.Title className="mb-1 text-secondary">Closed</Card.Title>
                    </div>
                    <div id="card6-bubbles" className="bubble-container"></div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
         
          <Col md={4}>
            <Card className="shadow-lg border-0  h-100 rounded">
              <Card.Body>
                <Card.Title className="mb-3">Bar Chart</Card.Title>
                <Bar data={barChartData} options={{ responsive: true }} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow-lg border-0 h-100 rounded">
              <Card.Body>
                <img src={realImage} alt="Real" className="h-100 img-fluid" />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow-lg border-0  h-100 rounded">
              <Card.Body>
                <Card.Title className="mb-3">Calendar</Card.Title>
                <Calendar
                  onChange={setDate}
                  value={date}
                />
              </Card.Body>
            </Card>
          </Col>
        
         
        </Row>
      </section>
    </main>
  );
};

export default Dashboard;
