import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { Card, Row, Col, Container, Spinner, Alert, Form, Button } from 'react-bootstrap';
import { FaRupeeSign } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import useApi from '../api';
import moment from 'moment';
const EMIList = () => {
  const { id } = useParams();
  const [emiDetails, setEmiDetails] = useState([]);
  const [emiSummary, setEmiSummary] = useState([]);
  const [applicationData, setApplicationData] = useState({});
  const [productData, setProductData] = useState({});
  const [purposeData, setPurposeData] = useState({});
  const [filteredEmiDetails, setFilteredEmiDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [chartData, setChartData] = useState({ seriesData: [], categories: [] });
  const [donutData, setDonutData] = useState({ paid: 0, pending: 0 });
  const [pieData, setPieData] = useState({ paidAmount: 0, pendingAmount: 0 });

  const { getApplicationByID, getStatusColor } = useApi();

  useEffect(() => {
    const fetchEmiDetails = async () => {
      try {
        const response = await getApplicationByID(id);
        console.log("emi page id", response);
        if (response.data && response.code === 200) {
          console.log("emi page data", response.data);
          const { emiData, applicationData, productData, purposeData, emiSummary } = response.data;
       

          setApplicationData(applicationData);
          setProductData(productData);
          setPurposeData(purposeData);
          setEmiDetails(emiData);
          setEmiSummary(emiSummary);
          setFilteredEmiDetails(emiData);
          processChartData(emiData);
        } else {
          setError(response.data.message || 'No EMI details found');
        }
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchEmiDetails();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);
    const filtered = emiDetails.filter((emi) =>
      Object.values(emi).some((value) =>
        value.toString().toLowerCase().includes(query)
      )
    );
    setFilteredEmiDetails(filtered);
  };

  const processChartData = (data) => {
    const formatDate = (date) => {
      const [year, month] = date.split('-');
      return `${new Date(`${year}-${month}-01`).toLocaleString('default', { month: 'short' })} ${year}`;
    };

    const categories = data.length > 0 ? data.map(payment => formatDate(payment.due_date)) : [];
    const seriesData = [{
      name: 'EMI Amount',
      data: data.map(payment => parseFloat(payment.emi_amount) + parseFloat(payment.interest_amount))
    }];

    setChartData({ seriesData, categories });

    const paid = data.filter(emi => emi.emi_status === 'Paid')
      .reduce((total, emi) => total + parseFloat(emi.principle_amount), 0);
    const pending = data.filter(emi => emi.emi_status === 'Pending')
      .reduce((total, emi) => total + parseFloat(emi.principle_amount), 0);

    setDonutData({ paid, pending });

    const paidAmount = data.filter(emi => emi.emi_status === 'Paid')
      .reduce((total, emi) => total + parseFloat(emi.principle_amount), 0);
    const pendingAmount = data.filter(emi => emi.emi_status === 'Pending')
      .reduce((total, emi) => total + parseFloat(emi.principle_amount), 0);

    setPieData({ paidAmount, pendingAmount });
  };

  const isButtonDisabled = (emiStatus, dueDate) => {
    const currentDate = moment();
    const dueDateMoment = moment(dueDate, 'YYYY-MM-DD');
    const daysRemaining = dueDateMoment.diff(currentDate, 'days');

    if (emiStatus === 'Paid' || dueDateMoment.isAfter(currentDate)) {
      return true; // Disable the button if status is Paid or due date is in the future
    }

    if (emiStatus === 'Pending' && daysRemaining <= 10) {
      return false; // Enable the button if status is Pending and 10 or fewer days remain
    }

    return true; // Disable the button for all other cases
  };

  const columns = [
    {
      name: 'S. No',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '70px'
    },
    { name: 'Due Date', selector: row => row.due_date, sortable: true },
    { name: 'Principle Amount', selector: row => row.principle_amount, sortable: true },
    { name: 'Interest Amount', selector: row => row.interest_amount, sortable: true },
    { name: 'EMI Amount', selector: row => row.emi_amount, sortable: true },
    { name: 'Principal Repaid', selector: row => row.principal_repaid, sortable: true },
    { name: 'Balanced Amount', selector: row => row.balanced_amount, sortable: true },
    {
      name: 'EMI Status',
      selector: row => row.emi_status,
      sortable: true,
      cell: row => (
        <div
        style={{
          backgroundColor: getStatusColor(row.emi_status),
          width: '60px',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          {row.emi_status}
        </div>
      )
    },
    {
      name: 'Action',
      selector: row => row.emi_status,
      cell: row => (
        <Button
          variant="primary"
          style={{
            backgroundColor: '#006666',
            borderColor: '#006666',
            fontSize: '13px', // Correct property name
            borderRadius: '4px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3px 8px' // Ensure there is some padding
          }}
          disabled={isButtonDisabled(row.emi_status, row.due_date)}
        >
          Pay Now
        </Button>
      ),
      width: '150px'
    }
    
  ];


  const BarChartOptions = {
    chart: {
      type: 'bar',
      height: 400,  // Increased height
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',  // Adjusted bar width
        endingShape: 'rounded',  // Rounded bar ends
      }
    },
    xaxis: { categories: chartData.categories },
    yaxis: {
      labels: { formatter: (value) => parseFloat(value).toFixed(0) }
    },
    colors: ['#006666'],
    legend: { position: 'top' },
    dataLabels: {
      enabled: false,
      formatter: (value) => parseFloat(value).toFixed(0),
      style: { colors: ['#000000'], fontSize: '12px', fontWeight: 'bold' },
      offsetX: 10,
      offsetY: 0
    },
    tooltip: { y: { formatter: (value) => parseFloat(value).toFixed(0) } }
  };

  const DonutChartOptions = {
    chart: { type: 'donut', height: 350 },
    labels: ['Paid', 'Pending'],
    colors: ['#008000', '#e09b03'],
    legend: { position: 'right' }
  };

  const PieChartOptions = {
    chart: { type: 'pie', height: 350 },
    labels: ['Paid', 'Pending'],
    colors: ['#008000', '#e09b03'],
    legend: { position: 'right' }
  };

  const calculateDisbursedAmount = () => {
    const { loan_amount, insurance_fee, processing_fee } = applicationData;
    if (loan_amount && insurance_fee && processing_fee) {
      return (
        parseFloat(loan_amount) -
        (parseFloat(insurance_fee) + parseFloat(processing_fee))
      ).toFixed(2);
    }
    return 'N/A';
  };

  return (
    <main id="main" className="main">
    <Container>
    <Row>
    <Col xs={12} md={4} className="mb-4">
      <Card className="shadow h-100">
        <Card.Body>
          <h5 className="section-heading">Application Details</h5>
          <div className="detail-list">
            <div className="detail-item">
              <span className="label">Product Name</span>
              <span className="colon">:</span>
              <span className="value">{productData.name || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Purpose Name</span>
              <span className="colon">:</span>
              <span className="value">{purposeData.name || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Loan Amount</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.loan_amount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Loan Tenure</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.loan_tenure || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Applied Date</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.applied_date || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Status</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.application_status || 'N/A'}</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>

    <Col xs={12} md={4} className="mb-4">
      <Card className="shadow h-100">
        <Card.Body>
          <h5 className="section-heading">Disbursed Details</h5>
          <div className="detail-list">
            <div className="detail-item">
              <span className="label">Disbursed Date</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.disbursed_date || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Amount</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.disbursed_amount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Interest Rate</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.interest_rate || 'N/A'}%</span>
            </div>
            <div className="detail-item">
              <span className="label">Processing Fee</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.processing_fee || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Insurance Fee</span>
              <span className="colon">:</span>
              <span className="value">{applicationData.insurance_fee || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Disbursed Amount</span>
              <span className="colon">:</span>
              <span className="value">{calculateDisbursedAmount()}</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>

    <Col xs={12} md={4} className="mb-4">
      <Card className="shadow h-100">
        <Card.Body>
          <h5 className="section-heading">EMI Details</h5>
          <div className="detail-list">
            <div className="detail-item">
              <span className="label">Total EMI</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.totalEmiCount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Paid EMI</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.paidEmiCount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Pending EMI</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.pendingEmiCount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Total EMI</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.totalEmiAmount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Total Paid</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.totalPaidAmount || 'N/A'}</span>
            </div>
            <div className="detail-item">
              <span className="label">Pending</span>
              <span className="colon">:</span>
              <span className="value">{emiSummary.totalPendingAmount || 'N/A'}</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {loading ? (
                <div className="text-center my-4">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>


                  <Form.Control
                    type="text"
                    placeholder="Search EMI details..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="mb-3 w-25"

                  />
                  <DataTable
                    columns={columns}
                    data={filteredEmiDetails}
                    pagination
                    responsive
                  />
                </>
              )}
            </Card.Body>
          </Card>

          <Row>
            <Col md={6} className="mb-4">
              <Card className="shadow">
                <Card.Body>
                  <ApexCharts options={DonutChartOptions} series={[donutData.paid, donutData.pending]} type="donut" height={350} />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="shadow">
                <Card.Body>
                  <ApexCharts options={PieChartOptions} series={[pieData.paidAmount, pieData.pendingAmount]} type="pie" height={350} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mb-4">
              <Card className="shadow">
                <Card.Body>
                  <ApexCharts options={BarChartOptions} series={chartData.seriesData} type="bar" height={350} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </main>

  );
};

export default EMIList;
